<template>
  <svg viewBox="0 0 10 10">
    <path d="M 0.334 4.334 H 9.6673 V 5.6668 H 0.334 V 4.3335 Z" />
  </svg>
</template>

<script>
export default {
  name: "MinusSimpleIcon",
};
</script>
